import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby";
import './assets/styles/_index.scss';

const OtherNewsArticles = ({newsData, title}) => {

    const data = useStaticQuery(graphql`
    query GetAllNews {
      glstrapi {
          blogs(limit: 8){
              title
              id
              slug
          }
      }
    }
  `)

    const allNews = newsData ? newsData : data.glstrapi.blogs
    return (
        <Container className="news-articles-wrapper other-news-wrapper px-0">
        <Row>
            <Col lg={12}>
                <div className="news-details-border-line"></div>
            </Col>
        </Row>
        <section className="other-news-articles">
            <Row className="justify-content-center">
                <Col lg={12}>
                    <Row>
                        <Col lg={12}>
                            <h2 className="text-start">{title ? title : "Other News Articles"}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ul className="news-articles-list">
                                {
                                    allNews.map((data, i) => {
                                        return (
                                            <li>
                                                <div className="news-articles-link">
                                                    <Link to={"/latest-news/"+data?.slug}>{data?.title}</Link>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
        </Container>
    )
}

export default OtherNewsArticles