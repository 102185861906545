import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import $ from "jquery"
import "./BannerIntro.scss"
import parse from "html-react-parser"
import { Link } from "gatsby"
import { GetPageURL } from "../common/utils"
import tickImg from "../../images/banner-tick.svg"
import { scrollToElement } from "../utils/utils"
import PlayVideo from "../PlayVideo/PlayVideo"

const BannerIntro = ({
  heading,
  title,
  description,
  cta1,
  cta2,
  cta3,
  readTime,
  layout,
}) => {
  const CTA = ({ cta, color }) => {
    const [isPlay, setPlay] = useState(false)
    const themeColor =
      cta.theme === "green"
        ? "green"
        : cta.theme === "secondary"
        ? "white"
        : color
    const classNames = `btn btn-${themeColor} btn-${cta.theme}`

    useEffect(() => {
      $(".how-it-works-page .cta-section .btn-green").on("click", function (e) {
        $(".how-it-works-page .cta-section .btn-green").removeClass("active")
        $(this).addClass("active")
      })
    })

    if (cta.custom_link?.length > 0) {
      if (cta.custom_link[0] === "#") {
        return (
          <button
            className={classNames}
            onClick={() => {
              const element = document.getElementById(
                cta.custom_link.replace("#", "")
              )
              if (element) {
                scrollToElement(element, 120)
              }
            }}
          >
            {cta.label}
          </button>
        )
      } else if (cta.custom_link.includes("$video:")) {
        return (
          <>
            <button
              className={classNames}
              onClick={() => {
                setPlay(true)
              }}
            >
              {cta.label}
            </button>
            {isPlay && (
              <PlayVideo
                isOpen={isPlay}
                stopPlay={setPlay}
                videoId=""
                isCloseFunction={setPlay}
                videourl={cta.custom_link.replace("$video:", "")}
                htmlink={""}
              />
            )}
          </>
        )
      }
    }

    return (
      <Link
        target={cta?.target === "new_window" ? "_blank" : ""}
        to={GetPageURL(cta.menu_item, cta.custom_link)}
        className={classNames}
      >
        {cta.label}
      </Link>
    )
  }

  return (
    <div className="section-p banner-into-wrapper">
      <Container className="banner-container">
        {layout === "static_template" && (
          <img src={tickImg} alt="banner-tick" className="banner-tick" />
        )}
        <div className="content">
          <h4 className="heading">{heading}</h4>
          <h1 className="title">{title}</h1>
          {description && (
            <div className="description">{parse(description)}</div>
          )}
          {(cta1 || cta2 || cta3) && (
            <div className="cta-section">
              {cta1 && <CTA cta={cta1} color={"pink"} />}
              {cta2 && <CTA cta={cta2} color={"white"} />}
              {cta3 && <CTA cta={cta3} color={"pink"} />}
            </div>
          )}
          {readTime && (
            <div className="read-time d-flex align-items-center justify-content-center">
              <i className="icon icon-clock"></i> Read time{" "}
              <span className="read-time-divider">|</span> {readTime}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default BannerIntro
