export const scrollToElement = (element, offset = 1) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}
